<template>
  <v-card max-width="600px" min-width="360px" style="margin: auto">
    <v-toolbar color="#006ba1" dark flat>
      <template v-slot:extension>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab :key="'login'">
            <v-icon large>mdi-account</v-icon>
            {{ $t("login.tabs.login.title") }}
          </v-tab>
          <v-tab :key="'create'">
            <v-icon large>mdi-account-outline</v-icon>
            {{ $t("login.tabs.create.title") }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'login'">
        <v-card class="px-4">
          <v-card-text>
            <v-form ref="loginForm" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginEmail"
                    :label="$t('login.tabs.login.email')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginPassword"
                    :label="$t('login.tabs.login.password.label')"
                    :hint="$t('login.tabs.login.password.hint')"
                    counter
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                  <v-btn x-large block color="success" @click="login()">
                    {{ $t('login.tabs.login.controlls.login') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="'create'">
        <v-card flat>
          <v-card-text
            v-text="$t('login.tabs.create.description')"
          ></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <error-handler :error="error"></error-handler>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      error: null,
      tab: null,
      loginEmail: "",
      loginPassword: "",
      showPassword: false,
    };
  },
  methods: {
    login() {
      this.$axios({
        method: "post",
        url: this.$globals.api.user.login,
        data: {
          email: this.loginEmail,
          password: this.loginPassword,
        },
      })
        .then((response) => {
          this.$store.commit("login", response.data.data);
          this.$router.push({ name: "home" });
        })
        .catch(function (error) {
          // handle error
          this.error = error.response;
        });
    },
  },
};
</script>